* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body,
html {
  height: 100%;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
}
.navbar p {
  padding-top: 2%;
  margin-bottom: 4%;
  color: white;
  font-size: 1.9em;
  font-weight: 150;
}
.navbar {
  position: relative;
  padding-bottom: 10px;
  padding: 5% 5% 0% 5%;
  background-color: rgb(0, 0, 0);
  font-weight: 400;
  font-style: normal;
  /* border-bottom: 1px solid white; */
}

.links {
  padding-top: 5%;
}
.links,
.linksafter {
  padding-bottom: 2%;
}
.links a,
.linksafter a {
  padding-right: 15%;
  text-decoration: none;
  color: aliceblue;
  font-weight: 150;
  font-size: 1.1em;
}
.links a:hover {
  font-weight: 400;
}

.loginlinks {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.linksafter {
  width: 40%;
}
.greeting {
  width: auto;
  font-weight: 400;
  color: white;
  padding-right: 10%;
  font-size: 1.1em;
}
.line {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075) 1%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.075) 99%
  );
}

.main-content {
  margin-top: 10%;
  padding: 0 3% 0 3%;
  flex: 1;
  margin-bottom: 5%;
}
h2 {
  font-size: 1.3em;
  padding-bottom: 2%;
  color: rgb(100, 148, 237);
}
.coinlistheader {
  font-size: 0.9em;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.63);
  padding-bottom: 2%;
  text-align: center;
}

.coinlist {
  width: 100%;
}

.symbol:hover {
  font-weight: 400;
}
.coinbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.61);
  transition: height 0.3s ease;
}
.coinbox:hover {
  height: 45px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgb(255, 255, 255);
}
.rank {
  width: 10%;
  color: rgb(255, 255, 255);
  text-align: left;
  font-weight: 300;
}
.coin,
.symbol {
  width: 15%;
  color: rgb(255, 254, 254);
  text-align: left;
  font-weight: 100;
}
.coinbox a {
  width: 15%;
  text-decoration: none;
  font-weight: 500;
}
.price {
  text-align: right;
  width: 17%;
  font-weight: 100;
}
.marketcap {
  text-align: right;
  width: 25%;
  font-weight: 100;
}
.change {
  width: 25%;
  text-align: center;
  font-weight: 100;
}
.pricechange {
  width: 25%;
  text-align: center;
}

.pricechange {
  color: rgb(0, 0, 0);
}

.pricechange.positive {
  color: #00ff29;
  font-weight: 200;
}

.pricechange.negative {
  color: red;
  font-weight: 300;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 5%;
  background-color: rgb(0, 0, 0);
  /* border-top: 1px solid white; */
}
.footer a {
  color: hwb(0 100% 0%);
  text-decoration: none;
}
h4 {
  font-weight: 200;
}
h4:hover {
  font-weight: 500;
}

/* pricechart */
.price-chart {
  padding: 1%;
}
.coinname {
  font-weight: bold;
  padding-bottom: 3%;
  font-weight: 400;
}
.current-price {
  font-weight: 200;
}
.chart-container {
  margin-top: 5%;
}

/* register/login */
form {
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  font-family: 'Montserrat', sans-serif;
}
form h2 {
  color: white;
  font-weight: 300;
  font-size: 1.4em;
  margin-bottom: 3%;
}
.username,
.password {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 2%;
  font-size: 1.1em;
}
.username label,
.password label {
  width: 30%;
}

form input {
  width: 45%;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 400;
  padding: 0 1% 0 1%;
}
form button {
  margin-top: 3%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1em;
  padding: 2%;
  width: 25%;
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
form button:hover {
  border: 2px solid rgb(255, 255, 255);
  font-weight: 600;
  cursor: pointer;
}
.message-box {
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.message-box p {
  font-size: 1.5em;
}
.message-box button {
  margin-top: 10%;
  width: 20%;
  border-radius: 5px;
  font-size: 1em;
  padding: 2%;
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.8em;
  }
  .links {
    padding-top: 2%;
    font-size: 1.3em;
    font-weight: 100;
  }
  .coinlistheader {
    font-size: 1.1em;
  }
  .footer {
    padding: 4%;
  }
  .username label,
  .password label {
    width: 30%;
  }

  form input {
    width: 40%;
  }
  .username,
  .password {
    font-size: 1.4em;
  }
}
@media (min-width: 768px) {
  .navbar {
    padding: 2% 10% 0 10%;
  }
  .navbar-content p {
    font-size: 2.3em;
    margin-bottom: 1%;
    font-weight: lighter;
  }
  .links {
    padding-bottom: 0%;
  }
  .main-content {
    font-size: 1.1em;
    padding: 0% 10% 0 10%;
    margin-top: 4%;
  }
  .coinlistheader {
    padding-bottom: 1%;
  }
  .footer {
    padding: 2%;
  }
  form button {
    width: 15%;
  }
}
@media (min-width: 1024px) {
  html,
  body {
    width: 100%;
    height: 100%;
  }

  .navbar {
    width: 100%;
    padding: 1% 3% 1% 3%;
    box-sizing: border-box;
  }
  .navbar-content {
    width: 80%;
    margin: 0 auto;
  }
  .navbar p {
    font-size: 2.3em;
    font-weight: 100;
    margin-bottom: 2%;
  }
  .links,
  .linksafter {
    padding-bottom: 0%;
  }

  .main-content {
    width: 80%;
    margin-bottom: 2%;
    padding: 0% 3% 0% 3%;
  }

  .price-chart {
    width: 70%;
  }

  .app {
    display: flex;
    align-items: center;
  }
  h4 {
    font-weight: 200;
  }
  .footer {
    padding: 1%;
    width: 100%;
  }

  /* register/login */
  form {
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    font-family: 'Montserrat', sans-serif;
  }
  form h2 {
    color: white;
    font-weight: 300;
    font-size: 1.4em;
    margin-bottom: 3%;
  }
  .username,
  .password {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 2%;
  }
  .username label,
  .password label {
    width: 15%;
    font-size: 0.9em;
  }
  /* form */

  form input {
    width: 20%;
    font-size: 0.8em;
  }
  form button {
    font-weight: 400;
    font-size: 1em;
    padding: 1%;
    width: 10%;
  }
  .message-box button:hover,
  form button:hover {
    border: 2px solid rgb(255, 255, 255);
    font-weight: 600;
    cursor: pointer;
  }
  .message-box {
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .message-box p {
    font-size: 1.5em;
  }
  .message-box button {
    margin-top: 5%;
    width: 10%;
    padding: 1%;
  }
}
@media (min-width: 1280px) {
  .navbar p {
    font-size: 2.8em;
    font-weight: 100;
  }
  .coinbox,
  .coinlist {
    font-size: 1.1em;
  }
}
